import { Link, type LinkProps } from '@remix-run/react';
import { Fragment } from 'react';

export type PublicLibraryBreadcrumbItem = {
  label: string;
} & (
  | {
      kind: 'button';
      onClick: () => void;
    }
  | {
      kind: 'link';
      to: LinkProps['to'];
    }
  | {
      kind: 'noop';
    }
);

function PublicLibraryBreadcrumb(props: { item: PublicLibraryBreadcrumbItem }) {
  const { item } = props;

  switch (item.kind) {
    case 'button':
      return (
        <button
          type='button'
          className='hover:underline hover:text-white'
          onClick={item.onClick}
        >
          {item.label}
        </button>
      );
    case 'link':
      return (
        <Link to={item.to} className='hover:underline hover:text-white'>
          {item.label}
        </Link>
      );
    case 'noop':
      return <p>{item.label}</p>;
  }
}

export function PublicLibraryBreadcrumbs(props: {
  items: (PublicLibraryBreadcrumbItem | null)[];
}) {
  const { items } = props;

  const filteredItems = items.filter(Boolean) as PublicLibraryBreadcrumbItem[];

  return (
    <div className='flex items-center gap-1 text-icon-gray transition-all tracking-wider'>
      {filteredItems.map((item, index) => (
        <Fragment key={index}>
          <PublicLibraryBreadcrumb item={item} />
          {index < filteredItems.length - 1 && <p>{'>'}</p>}
        </Fragment>
      ))}
    </div>
  );
}
